<template>
  <div class="text">
    <TopBar :type="1" :logo="logo" />

    <div class="cont_info">
      <p class="img"><img src="@/assets/m/pop/com_icon.png" alt="" /></p>
      <div class="text_info">请于电脑端填写~</div>
    </div>
    <div class="go_back" @click="goBack()">返回首页</div>
  </div>
</template>
  <script>
import TopBar from "@/m/common/topBar.vue";

import { mapMutations } from "vuex";
export default {
  name: "TextContent",
  data() {
    return {
      answer: "",
    };
  },
  components: {
    TopBar,
  },
  mounted() {},
  methods: {
    ...mapMutations(["ShowTipModal", "CloseTipModal"]), //登录弹窗
    goBack(){
      this.$router.replace('/')
    }
  },
};
</script>
  <style scoped lang="scss">
.text {
  height: 100vh;
  width: 100%;
  .cont_info {
    p.img {
      width: 520px;
      height: auto;
      margin: 258px auto 0;
      img {
        width: 100%;
      }
    }
    .text_info {
      text-align: center;
      font-size: 36px;
      letter-spacing: 3px;
      margin-top: 32px;
      color: #333333;
    }
  }
  .go_back{
    width: 340px;
    height: 108px;
    border-radius: 788px 788px 788px 788px;
    border: 2px solid #D8D8D8;
    text-align: center;
    line-height: 108px;
    margin: 0 auto;
    font-size: 36px;
    color: #3D3D3D;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 208px;
  }
}
</style>